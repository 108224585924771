import React from "react";
import { Button } from "@blueprintjs/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, addDays } from "date-fns";

import "@blueprintjs/core/lib/css/blueprint.css";

type MyState = {
  selectedDate: Date;
  links: { title: string; date: string; link: string }[];
};

type MyProps = {};

class DateLinksPage extends React.Component<MyProps, MyState> {
  state: MyState = {
    selectedDate: new Date(), // Default to today's date
    links: [], // Links generated based on the selected date
  };

  constructor(props: MyProps) {
    super(props);
    this.onTodayClick = this.onTodayClick.bind(this);
    this.onTomorrowClick = this.onTomorrowClick.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  componentDidMount() {
    this.generateLinks(new Date()); // Generate links for the current date when the component mounts
  }

  generateLinks(selectedDate: Date) {
    const formattedDate = format(selectedDate, "yyyy-MM-dd");
    const newLinks = [
      {
        title: "Ligne P - Paris -> La Ferté-sous-Jouarre",
        date: formattedDate,
        link: `https://www.transilien.com/fr/les-fiches-horaires/resultats/?completeDayResearch=true&date=${formattedDate}&departure=Paris%20Est&destination=La%20Fert%C3%A9-sous-Jouarre&idStopPointDestination=stop_area%3AIDFM%3A68918&idUic7Departure=stop_area%3AIDFM%3A71359&errors=&startTimeSlot=08%3A00&endTimeSlot=18%3A00`,
      },
      {
        title: "Ligne P - La Ferté-sous-Jouarre -> Paris",
        date: formattedDate,
        link: `https://www.transilien.com/fr/les-fiches-horaires/resultats/?completeDayResearch=true&date=${formattedDate}&departure=La%20Fert%C3%A9-sous-Jouarre&destination=Paris%20Est&idStopPointDestination=stop_area%3AIDFM%3A71359&idUic7Departure=stop_area%3AIDFM%3A68918&errors=&startTimeSlot=08%3A00&endTimeSlot=18%3A00`,
      },
      {
        title: "Bus 31 - La Ferté-sous-Jouarre -> Le Potager",
        date: formattedDate,
        link: `https://me-deplacer.iledefrance-mobilites.fr/fiches-horaires/bus/line%3AIDFM%3AC00920/horaires?departureId=stop_area%3AIDFM%3A68918&arrivalId=stop_area%3AIDFM%3A69022&date=${formattedDate}T07%3A00%3A00.000Z`,
      },
      {
        title: "Bus 31 - Le Potager -> La Ferté-sous-Jouarre",
        date: formattedDate,
        link: `https://me-deplacer.iledefrance-mobilites.fr/fiches-horaires/bus/line%3AIDFM%3AC00920/horaires?arrivalId=stop_area%3AIDFM%3A68918&departureId=stop_area%3AIDFM%3A69022&date=${formattedDate}T07%3A00%3A00.000Z`,
      },
    ];
    this.setState({ links: newLinks });
  }

  otherLinks() {
    return [
      {
        title: "Ligne P - Prochains départs Paris",
        link: "https://www.transilien.com/fr/horaires/prochains-departs/?departure=Paris+Est&uicDeparture=8711300&arrival=La+Fert%C3%A9-sous-Jouarre&pmr=false&uicArrival=8711651",
      },
      {
        title: "Ligne P - Prochains départs La-Ferté-sous-Jouarre",
        link: "https://www.transilien.com/fr/horaires/prochains-departs/?departure=La+Fert%C3%A9-sous-Jouarre&uicDeparture=8711651&arrival=Paris+Est&pmr=false&uicArrival=8711300",
      },
    ];
  }

  onTodayClick() {
    const today = new Date();
    this.setState({ selectedDate: today });
    this.generateLinks(today);
  }

  onTomorrowClick() {
    const today = new Date();
    this.setState({ selectedDate: addDays(today, 1) });
    this.generateLinks(today);
  }

  onDateChange(date: Date | null) {
    if (date) {
      this.setState({ selectedDate: date });
      this.generateLinks(date);
    }
  }

  render() {
    const form = (
      <div className="date-links-form">
        <Button
          className="bp3-intent-primary"
          text="Set to Today"
          onClick={this.onTodayClick}
        />
        <Button
          className="bp3-intent-primary"
          text="Set to Tomorrow"
          onClick={this.onTomorrowClick}
        />

        <div className="date-picker-container">
          <label>Select Date:</label>
          <DatePicker
            selected={this.state.selectedDate}
            onChange={this.onDateChange}
            dateFormat="yyyy-MM-dd"
            className="bp3-input"
          />
        </div>
      </div>
    );

    const linksList = (
      <ul className="date-links-list">
        {this.state.links.map((linkData, index) => (
          <li key={index}>
            <a href={linkData.link} target="_blank" rel="noopener noreferrer">
              {linkData.title} ({linkData.date})
            </a>
          </li>
        ))}
      </ul>
    );

    const otherLinksList = (
      <ul className="date-links-list">
        {this.otherLinks().map((linkData, index) => (
          <li key={index}>
            <a href={linkData.link} target="_blank" rel="noopener noreferrer">
              {linkData.title}
            </a>
          </li>
        ))}
      </ul>
    );

    return (
      <div className="date-links-page">
        {form}
        {linksList}
        {otherLinksList}
      </div>
    );
  }
}

export default DateLinksPage;

// Idees
// Telecharger les fiches horaires des 7 prochains jours dans le raspberry pi
